<template>
    <main>
        <section class="d-print-none">
            <spinner v-if="!allImagesLoaded" text="Building..."/>
        </section>

        <section
            class="d-print-block print-area mb-n3"
            :class="{'d-none': !allImagesLoaded}"
        >
            <article class="sheet cover-page" data-test="main cover page">
                <img class="cover-image img-fluid" :src="imageSources.cover"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-100 w-100 d-flex flex-column justify-content-between">
                    <div class="cover-header ms-auto text-end">
                        <img class="img-fluid w-25" :src="imageSources.wilandIconBox"/>
                    </div>

                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <div class="cover-title">
                            <div>
                                <h1 class="mb-3">{{ chartData.persona.name }}</h1>
                                <h2>A PersonaBuilder&trade; Report</h2>
                            </div>
                        </div>
                        <div class="w-75 p-3 ms-auto mt-5 h3 bg-info">
                            <div class="text-center">
                                <h3>{{ Utils.dateFormat(chartData.persona.updateDate, {format: 'date'}) }}</h3>
                            </div>
                        </div>
                    </div>

                    <img class="cover-footer img-fluid w-25 mx-auto"
                         :onerror="Utils.imageFallback(imageSources.wilandLogo)"
                         :src="logoPath"
                    />
                </div>
            </article>

            <article class="sheet content-page" data-test="executive overview">
                <page-header :content="headerContent('Executive Overview')"/>

                <h1 id="executiveOverview">Executive Overview</h1>
                <p class="mt-3">This Persona was built by {{ personaBuiltBy }} for the {{ account.name }} account using Wiland's powerful PersonaBuilder application. It is based on {{ personaCount }} people possessing these characteristics:</p>

                <characteristics
                    v-memo="[persona]"
                    class="p-0 ms-5"
                    inner-container-class=""
                    :mode="personaMode.REPORT"
                    outer-container-class=""
                    :persona="persona"
                    topic-style="text"
                />

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet cover-page section-cover" data-test="methodology cover page">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Methodology</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page" data-test="methodology page">
                <page-header :content="headerContent('Methodology')"/>

                <p>Customer personas have long provided a way to understand and segment consumer cohorts. But marketers have struggled to make personas actionable when it comes to real-world media targeting.</p>

                <p>That's why Wiland PersonaBuilder&trade;&mdash;<strong>the world's most powerful persona creation tool</strong>&mdash;is also designed to produce ready-to-deliver audiences for activation in digital advertising campaigns. The intuitive PersonaBuilder application makes it easy to create robust, detailed prospect and customer personas based on relevant, continuously refreshed data&mdash;vast predicted spend signals, social media engagement data, and other information.
                </p>

                <blockquote>Bridge the gap between your strategy and media buying teams</blockquote>

                <p>PersonaBuilder enables you to create robust, detailed personas that can immediately be converted into audiences ready for activation in digital advertising campaigns. By closing the gap between your strategy and media buying teams, the insights you gain become actionable in the form of large audiences ready to activate across all platforms, channels, and devices.</p>

                <p>PersonaBuilder insights are driven by the power of GraphMassive&trade;,
                    <strong>the largest independent consumer graph</strong> of scored social data anonymously indexed to actionable offline information. GraphMassive is the indusry's only searchable consumer graph that maps tens of billions of consumer relationships and interests across social media and joins them to offline demographics, purchase transactions, spending intensities, and location. GraphMassive is built specifically for data-driven marketers seeking to understand who is behind social activity related to their brands or their competitor's brands.
                </p>

                <blockquote>How PersonaBuilder Works</blockquote>
                <img alt="PersonaBuilder&trade; methodology"
                     class="img-fluid"
                     :src="`${this.imageSourceRoot}/methodology.png`"
                />
                <p>Personas created using the application are analyzed to produce high-performance audiences that can be delivered to any leading platform or DSP for activation.</p>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet cover-page section-cover" data-test="summary cover page">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Summary</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page" data-test="demographics summary page">
                <page-header :content="headerContent('Summary')"/>

                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.DEMOGRAPHICS]" size="lg"/>
                    </template>
                    <template #title> Demographics</template>
                    <template #content>
                        <div class="row g-0 m-n3">
                            <div v-for="(chart, index) of chartData.summary.demographics" :key="index"
                                 class="col-3"
                                 data-chart-container
                            >
                                <highcharts :options="chart" />
                            </div>
                        </div>
                    </template>
                </summary-section>

<!--                <p>Significant characteristics of this persona are captured in the Unique Facts chart, while the top brand engagement and personal interest categories are reflected in these social engagement summaries.</p>-->

                <div class="row">
                    <div class="col-6">
                        <summary-section>
                            <template #icon>
                                <img class="logo-icon"
                                     :src="iconPath['personaBuilder']"
                                     alt=""
                                     tabindex="-1"
                                />
                            </template>
                            <template #title>
                                Unique Facts
                            </template>
                            <template #content>
                                <wedge-chart-section :data="{items: chartData.summary.uniqueFacts}"/>
                            </template>
                        </summary-section>
                    </div>

                    <div v-if="chartData.summary.highLevelRfm !== false" class="col-6">
                        <summary-section>
                            <template #icon>
                                <font-awesome-icon :icon="['duotone', FeatureIcons.HIGH_LEVEL_RFM]" size="lg"/>
                            </template>
                            <template #title>
                                Recency, Frequency, and Monetary
                            </template>
                            <template #content>
                                <highcharts :options="chartData.summary.highLevelRfm"/>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page" data-test="consumer summary page">
                <page-header :content="headerContent('Summary')"/>

                <section>
                    <h2>Spending Behaviors</h2>
                    <div class="row">
                        <div class="col-6">
                            <summary-section v-if="allowFeature('conglomerateRfm')">
                                <template #icon>
                                    <font-awesome-icon :icon="['duotone', FeatureIcons.CONGLOMERATE_RFM_MARKETS]" size="lg"/>
                                </template>
                                <template #title>
                                    Market Spend
                                </template>
                                <template #content>
                                    <highcharts
                                        :key="`market_affinity_summary`"
                                        :options="chartData.summary.conglomerateRfmMarket"
                                    />
                                </template>
                            </summary-section>

                            <summary-section v-else>
                                <template #icon>
                                    <font-awesome-icon :icon="['duotone', FeatureIcons.PAST_PURCHASES]" size="lg"/>
                                </template>
                                <template #title>
                                    Past Purchase Activity
                                </template>
                                <template #content>
                                    <highcharts
                                        v-for="(pastPurchaseChart, index) of chartData.summary.pastPurchaseActivity"
                                        :key="`past_purchase_${index}`"
                                        :options="pastPurchaseChart"
                                    />
                                </template>
                            </summary-section>
                        </div>

                        <div v-if="chartData.summary.predictedSpendActivity.length > 0" class="col-6">
                            <summary-section>
                                <template #icon>
                                    <font-awesome-icon :icon="['duotone', FeatureIcons.PREDICTED_SPEND]" size="lg"/>
                                </template>
                                <template #title>
                                    Predicted Spend
                                </template>
                                <template #content>
                                    <highcharts
                                        v-for="(predictedSpendChart, index) of chartData.summary.predictedSpendActivity"
                                        :key="`consumer_spend_${index}`"
                                        :options="predictedSpendChart"
                                    />
                                </template>
                            </summary-section>
                        </div>
                    </div>
                </section>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page" data-test="political and social summary page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Political Affiliation</h2>
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.POLITICAL]" size="lg"/>
                    </template>
                    <template #title>
                        Political Party Affiliation
                    </template>
                    <template #content>
                        <highcharts :options="chartData.summary.politicalPartyAffiliation"/>
                    </template>
                </summary-section>

                <h2>Times of High and Low Social Media Activity</h2>
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]" size="lg"/>
                    </template>
                    <template #title>Weekly Activity</template>
                    <template #content>
                        <highcharts :options="chartData.summary.socialWeeklyActivity"/>
                    </template>
                </summary-section>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page" data-test="geography summary page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Media Market Analysis</h2>
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.GEOGRAPHY]" size="lg"/>
                    </template>
                    <template #title>
                        Geography
                    </template>
                    <template #content>
                        <div class="row">
                            <div class="col-12">
                                <highcharts
                                    constructor-type="mapChart"
                                    :options="chartData.summary.geography.chart"
                                    style="overflow: visible !important;"
                                />
                            </div>

                            <!-- Detail sidebar -->
                            <div v-for="detailSection of chartData.summary.geography.detail"
                                 class="col-6"
                            >
                                <div class="card bg-blue-10 border-0 p-3 mb-3 overflow-hidden">
                                    <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                                    <div>
                                        <table class="text-primary w-100">
                                            <tr v-for="item of detailSection.items.index.slice(0, 20)"
                                                :key="item.label">
                                                <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </summary-section>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet cover-page section-cover" data-test="demographics cover page">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Demographic Details</h1>
                    </div>
                </div>
            </article>

            <article
                v-for="(chartPage, index) of paginatedCharts('demographics')" :key="index"
                class="sheet content-page"
                data-test="demographic detail page"
            >
                <page-header :content="headerContent('Demographics')"/>

                <template v-if="index === 0">
                    <p>Wiland's data and analytics use real consumer demographic data from the GraphMassive consumer graph to ensure the highest level of accuracy and usefulness. We accomplish this by deterministically joining all the data available for each consumer in GraphMassive in order to build comprehensive profiles of the characteristics exhibited by the consumers in thie group segment.</p>
                </template>

<!--                {{chartData.demographics.associatedData}}-->

                <chart-grid
                    :charts="chartPage.charts"
                    :charts-associated-data="chartData.demographics.associatedData"
                />

                <page-footer :content="footerContent()"/>
            </article>

            <article v-for="(element, index) of chartData.geographicAreas" :key="index"
                class="sheet content-page"
                data-test="geography detail page">
                <page-header :content="headerContent('Geographic Areas')"/>

                <h3>{{ element.title }}</h3>

                <div class="row">
                    <div class="col-12">
                        <highcharts
                            constructor-type="mapChart"
                            :options="element.chart"
                            style="overflow: visible !important;"
                        />
                    </div>

                    <!-- Detail sidebar -->
                    <div v-for="detailSection of element.detail"
                         class="col-6"
                         :class="{'offset-3': element.detail.length === 1}"
                    >
                        <div class="card bg-blue-10 border-0 p-3 mb-3 overflow-hidden">
                            <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                            <div>
                                <table class="text-primary w-100">
                                    <tr v-for="item of detailSection.items.index.slice(0, 20)"
                                        :key="item.label">
                                        <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                        <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet cover-page section-cover" data-test="social activity cover page">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Social Activity</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page" data-test="social activity engagement page">
                <page-header :content="headerContent('Social Activity')"/>

                <h3>Engagement and Day Part Analysis</h3>

                <chart-grid
                    :charts="chartData.socialActivity.engagement.charts"
                    :charts-associated-data="chartData.socialActivity.engagement.associatedData"
                />

                <highcharts :options="chartData.socialActivity.weeklyActivity.chart"/>

                <div class="row row-eq-height">
                    <div v-for="detailSection of chartData.socialActivity.weeklyActivity.detail"
                         class="col"
                    >
                        <div
                            class="card bg-blue-10 border-0 p-3 mb-3">
                            <u class="h5 text-primary mb-3 mx-auto text-nowrap" v-html="detailSection.name"></u>
                            <div style="max-height: 17em; overflow: auto">
                                <table class="text-primary w-100">
                                    <tr v-for="item of detailSection.items"
                                        :key="item.index"
                                    >
                                        <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                        <td class="align-top text-end text-nowrap" v-html="item.value"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article v-if="displayTopSocialContent" class="sheet content-page" data-test="social activity detail page">
                <page-header :content="headerContent('Social Activity')"/>

                <h3>Top Content</h3>

                <div v-for="chartElements of chartData.socialActivity.topContent"
                     class="row"
                     :data-chart-id="chartElements.chart.id"
                     :key="chartElements.chart.id"
                >
                    <div class="col-7">
                        <highcharts :options="chartElements.chart"/>
                    </div>

                    <!-- Detail sidebar -->
                    <div class="col-5">
                        <div class="card bg-blue-10 border-0 p-3 mb-3">
                            <u class="h5 text-primary mb-3 mx-auto text-nowrap"
                               v-html="`${chartElements.detail.contentTypeLabel} Used by Persona`"
                            ></u>
                            <table class="text-primary w-100">
                                <thead>
                                <tr>
                                    <th><u>{{ chartElements.detail.contentTypeLabel }}</u></th>
                                    <th class="text-center"><u>Index</u></th>
                                    <th class="text-center"><u>Total Post Count</u></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr v-for="item of chartElements.detail.items"
                                    :key="item.index"
                                >
                                    <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                    <td class="align-top text-center" v-html="item.indexValue"></td>
                                    <td class="align-top text-center" v-html="item.countValue"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article
                v-for="(chartPage, index) of paginatedCharts('brandInterestEngagement', 1)"
                :key="index"
                class="sheet content-page"
                data-test="brand and interest detail page"
            >
                <page-header :content="headerContent('Brand and Interest Engagement')"/>

                <template v-if="index === 0">
                    <h2>Top Categories Followed</h2>
                    <p>Index values are calculated using a baseline built from the active social accounts in the U.S. Brand and Interest handles are curated based on Wiland's proprietary taxonomy.</p>
                </template>

                <chart-grid chart-wrapper-class="col-12"
                            :charts="chartPage.charts"
                />

                <page-footer :content="footerContent()"/>
            </article>

            <section v-if="allowFeature('conglomerateRfm')">
                <article class="sheet cover-page section-cover" data-test="market spend cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Market Spend</h1>
                        </div>
                    </div>
                </article>

                <article class="sheet content-page" data-test="market spend detail page">
                    <page-header :content="headerContent('Market Spend')"/>

                    <p>Market Spend data is based on actual historical credit card data sourced from 3rd party vendors. The data describes which individuals have had above-average credit card spending in a given market over the past 24 months. Groups with above-average historical spending in a market are likely to continue spending in that market moving forward.</p>

                    <chart-grid :charts="chartData.conglomerateRfmMarket.charts" chart-wrapper-class="col-10 offset-1"/>

                    <page-footer :content="footerContent()"/>
                </article>

                <section v-if="chartData.conglomerateRfmMarket.selectedMarkets !== null">
                    <article
                        v-for="(selectedMarket, index) of chartData.conglomerateRfmMarket.selectedMarkets"
                        :key="`selected_market_${index}`"
                        class="sheet content-page"
                        data-test="market spend selected market page"
                    >
                        <page-header :content="headerContent(`Market Spend`)"/>

                        <h3>{{ selectedMarket.name }}</h3>

                        <chart-grid
                            :charts="selectedMarket.charts"
                            :charts-associated-data="selectedMarket.associatedData"
                        />

                        <page-footer :content="footerContent()"/>
                    </article>
                </section>
            </section>

            <section v-if="allowFeature('predictedSpend')">
                <article class="sheet cover-page section-cover" data-test="predicted spend cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Predicted Spend</h1>
                        </div>
                    </div>
                </article>

                <article class="sheet content-page" data-test="predicted spend detail page">
                    <page-header :content="headerContent('Predicted Spend')"/>

                    <h2>Predicted Spend: Top Categories</h2>
                    <p>Unique to the Wiland data universe, predicted spend is based on actual transaction data and modeled to find the most likely spending categories for this Persona.</p>

                    <highcharts :options="chartData.predictedSpendSummary"/>

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <section v-if="allowFeature('highLevelRfm')">
                <article class="sheet cover-page section-cover" data-test="high-level rfm cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Recency, Frequency, and Monetary</h1>
                        </div>
                    </div>
                </article>

                <article
                    v-for="(chartPage, index) of paginatedCharts('highLevelRfm')"
                    :key="index"
                    class="sheet content-page"
                    data-test="high-level rfm detail page"
                >
                    <page-header :content="headerContent('Recency, Frequency, and Monetary')"/>

                    <chart-grid
                        :charts="chartPage.charts"
                        :charts-associated-data="chartData.highLevelRfm.associatedData"
                    />

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <section v-if="!allowFeature('conglomerateRfm')">
                <article class="sheet cover-page section-cover" data-test="past purchases cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Past Purchases</h1>
                        </div>
                    </div>
                </article>

                <article v-for="(chartPage, index) of chartData.pastPurchases.pages"
                    :key="index"
                    class="sheet content-page"
                    data-test="past purchases detail page"
                >
                    <page-header :content="headerContent('Past Purchases')"/>

                    <section v-if="index === 0">
                        <p>Past purchase data represents the transaction level household purchases for the past 24 months, aggregated into product categories.</p>
                    </section>

                    <h3>24 Month Purchase Transactions: {{ chartPage.title }}</h3>

                    <chart-grid
                        :charts="chartPage.charts"
                        :charts-associated-data="chartData.pastPurchases.associatedData"
                    />

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <section
                v-if="chartData.hasOwnProperty('socialEngagement') || chartData.hasOwnProperty('userDefinedTopics')"
            >
                <article class="sheet cover-page section-cover" data-test="topics cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Added Charts</h1>
                        </div>
                    </div>
                </article>

                <article v-if="chartData.hasOwnProperty('socialEngagement')"
                     class="sheet content-page"
                     data-test="social detail page"
                >
                    <page-header :content="headerContent('Added Charts')"/>

                    <h3>Social Characteristics used to define this Persona</h3>
                    <chart-grid
                        :chart-wrapper-class="[chartData.socialEngagement.charts.length > 1 ? 'col-6 px-5' : 'col-12']"
                        chart-wrapper-class-ignore="col-12"
                        :charts="chartData.socialEngagement.charts"
                    />
                    <p class="text-size-sm">This chart contains the top 20 social characteristics used to define the Persona. If more characteristics were selected they are available in the online report but have been dropped from this print version.</p>

                    <page-footer :content="footerContent()"/>
                </article>

                <section
                    v-if="chartData.hasOwnProperty('userDefinedTopics') && chartData.userDefinedTopics.charts.length > 0">
                    <article v-for="(chartPage, index) of paginatedCharts('userDefinedTopics', 1)"
                        :key="index"
                        class="sheet content-page"
                        data-test="user-defined topic detail page"
                    >
                        <page-header :content="headerContent('Added Charts')"/>

                        <h3>Custom Topic: {{ chartPage.charts[0].topicName }}</h3>
                        <chart-grid chart-wrapper-class="col-12"
                                    :charts="chartPage.charts"
                        />
                        <p class="text-size-sm">This chart contains the top 20 characteristics in this custom topic. If this topic contained more than characteristics they are available in the online report but have been dropped from this print version.</p>

                        <page-footer :content="footerContent()"/>
                    </article>
                </section>
            </section>

            <!-- Back page -->
            <article class="sheet cover-page final" data-test="back page">
                <img class="cover-image img-fluid" :src="imageSources.backPage"/>
                <div class="cover-stripe" :src="imageSources.cover"></div>
                <div class="cover-content h-100 w-100 d-flex flex-column justify-content-between">
                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <div class="cover-title">
                            <h2 class="mb-3">This report was generated using the advanced analytics found in Wiland's PersonaBuilder application and the vast data resources found in Wiland's GraphMassive consumer graph.</h2>
                        </div>
                        <div class="w-75 p-3 ms-auto mt-5 h3 bg-info">
                            <div class="text-center">
                                <h2>Wiland</h2>
                                <h3>The best predictor of consumer response</h3>
                            </div>
                        </div>
                    </div>
                    <div class="text-center cover-footer">
                        <img class="w-25 m-3" :src="imageSources.wilandLogo"/>
                        <ul class="list-inline list-piped lead m-3">
                            <li class="list-inline-item">Denver</li>
                            <li class="list-inline-item">New York</li>
                            <li class="list-inline-item">Washington, D.C.</li>
                            <li class="list-inline-item">Little Rock</li>
                        </ul>
                        <div class="text-primary fw-bold">
                            <span class="fa-stack">
                                <font-awesome-icon class="fa-stack-2x text-gray-25" icon="circle"/>
                                <font-awesome-icon class="fa-stack-1x text-light"
                                                   icon="mouse-pointer"
                                                   style="transform: rotate(-15deg)"
                                />
                            </span>
                            wiland.com
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </main>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';
    import {defineAsyncComponent} from 'vue';
    import Highcharts from 'highcharts';
    import highchartsMore from 'highcharts/highcharts-more';
    import {Chart} from 'highcharts-vue';
    import HighchartsMap from 'highcharts/modules/map';
    import solidGaugeInit from 'highcharts/modules/solid-gauge';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import SummarySection from 'Components/common/report/summary-section.vue';
    import SummaryWidget from 'Components/common/report/summary-widget.vue';
    import PageFooter from 'Components/common/report/page-footer.vue';
    import PageHeader from 'Components/common/report/page-header.vue';
    import WedgeChartSection from 'Components/common/report/wedge-chart-section.vue';
    import ChartGrid from 'Components/persona/report/chart-grid.vue';
    import PersonaTopicWidget from 'Components/persona/report/custom-layout/persona-topic-widget.vue';
    import {useAccountStore} from 'Stores/account';
    import {FeatureIcons/*, useAppStore*/} from 'Stores/common/app';
    import {PersonaMode} from 'Stores/persona';
    import {iconPath} from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';

    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');

    highchartsMore(Highcharts);
    HighchartsMap(Highcharts);
    solidGaugeInit(Highcharts);

    @Component<PersonaSummaryPdf>({
        setup() {
            return {
                accountStore: useAccountStore()
            };
        },
        components: {
            highcharts: Chart,
            Characteristics: defineAsyncComponent(() =>
                import('Components/persona/characteristics.vue')
            ),
            ChartGrid,
            FontAwesomeIcon,
            PageFooter,
            PageHeader,
            PersonaTopicWidget,
            Spinner,
            SummaryWidget,
            SummarySection,
            WedgeChartSection,
        }
    })
    class PersonaSummaryPdf extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        chartData: any;

        @Prop({
            type: Boolean,
            default: false
        })
        reportReady;

        defaultSocialContext = 'twitter';
        demographicsExpanded: boolean = false;
        iconPath = iconPath;
        imageCount: number = 0;
        imagesLoadedCount: number = 0;
        imageSourceRoot = '/assets/images/report-pdf';
        imageSources = {
            backPage: `${this.imageSourceRoot}/back-page.jpg`,
            cover: `${this.imageSourceRoot}/cover.jpg`,
            section: `${this.imageSourceRoot}/section.jpg`,
            stripe: `${this.imageSourceRoot}stripe-bar.png`,
            wilandLogo: `${this.imageSourceRoot}/../wiland-logo.svg`,
            wilandIconBox: `${this.imageSourceRoot}/wiland-icon-box-white.png`,
        }
        itemContext = {
            brand: this.defaultSocialContext,
            interest: this.defaultSocialContext,
            politicalSocialEngagement: this.defaultSocialContext,
        };
        personaMode = PersonaMode;

        FeatureIcons = FeatureIcons;
        Utils = Utils;

        mounted() {
            this.prepareDocument();
        }

        unmounted() {
            const images: HTMLImageElement[] = document.querySelectorAll('.d-print-block img');
            this.imageCount = images.length;
            for (const image of images) {
                image.removeEventListener('load', this.incrementImagesLoadedCount);
                image.removeEventListener('error', this.handleImageCount);
            }
        }

        get account() {
            return this.accountStore.getAccount;
        }

        get allImagesLoaded() {
            return this.imagesLoadedCount >= this.imageCount; // Allow for some wiggle room...
        }

        get displayTopSocialContent() {
            return false; // PBUILDER-2782 - top content should NEVER appear
            // return this.chartData.socialActivity.topContent.every(chartData => chartData.chart !== false);
        }

        get logoPath(): string {
            return this.chartData.account?.logoFile?.filePathUri?.length > 0 ?
                Utils.getNonPrefixedFileUri(this.chartData.account.logoFile.filePathUri) :
                this.imageSources.wilandLogo;
        }

        get persona() {
            return this.chartData.persona;
        }

        get personaBuiltBy(): string {
            return `${this.persona.updated?.user?.firstName || 'unknown'} ${this.persona.updated?.user?.lastName || 'unknown'}`;
        }

        get personaCount(): string {
            // return Utils.formatValue(this.chartData.insights.social?.groupMetrics?.consumerCount || 0, 'separated'); // Old - Twitter/Instagram count
            return <string>Utils.formatValue(this.chartData.insights.social?.groupMetrics?.groupCount || 0, 'separated');
        }

        allowFeature(featureType: string): boolean {
            let dataExists = true;
            switch (featureType) {
                case 'conglomerateRfm':
                    dataExists = this.chartData.hasOwnProperty('conglomerateRfmMarket');
                    break;
                case 'predictedSpend':
                    dataExists = this.chartData.hasOwnProperty('consumerSpend');
                    break;
            }

            return dataExists && this.accountStore.allowDataClass(featureType, this.persona.businessUseTypeIdentifier);
        }

        footerContent() {
            return {
                left: `<div class="page-number"></div>`,
                center: `<img class="img-fluid" src="/assets/images/powered-by-graphmassive-gray-50.svg" style="max-height: 0.2in;"/>`,
                right: `&copy;${new Date().getFullYear()} Wiland, Inc.`,
            };
        }

        headerContent(sectionTitle: string) {
            return {
                left: `
                    <div class="list-inline list-piped">
                        <li class="list-inline-item"><strong>PersonaBuilder</strong></li>
                        <li class="list-inline-item">${sectionTitle}</li>
                    </div>
                `,
            };
        }

        paginatedCharts(section: string, segmentSize: number = 4) {
            const chartSource = this.chartData[section]?.charts;
            if (!chartSource.length) {
                return [];
            }

            const pageCount = Math.ceil((chartSource.length / segmentSize));
            let pages = [];

            for (let i = 0; pages.length < pageCount; ++i) {
                pages.push({
                    // page: i,
                    charts: chartSource.slice(i * segmentSize, (i * segmentSize) + segmentSize),
                });
            }

            return pages;
        }

        incrementImagesLoadedCount() {
            ++this.imagesLoadedCount;
        }

        handleImageCount(err) {
            if (err.target.src.indexOf('persona-builder-images') > -1) {
                // Ignore PB image host issues in case of missing asset
                --this.imageCount;

                return;
            }

            if (err.target.src.indexOf('twitter') > -1 || err.target.src.indexOf('twimg') > -1) {
                // If Twitter is continuing to fail, don't count this against the total
                --this.imageCount;

                return;
            }
        }

        prepareDocument() {
            // Wait until all images are loaded before printing
            const images: HTMLImageElement[] = document.querySelectorAll('.d-print-block img');
            this.imageCount = images.length;
            for (const image of images) {
                image.addEventListener('load', this.incrementImagesLoadedCount);
                image.addEventListener('error', this.handleImageCount);
            }
        }

        // printDocument() {
        //     window.print();
        // }

        // itemContextIcon(context: string) {
        //     return ['brands', this.itemContext[context]];
        // }

        setItemContext(context: string, value: string) {
            this.itemContext[context] = value;
        }

        // @Watch('allImagesLoaded')
        // onAllImagesLoaded(isLoaded) {
        //     if (isLoaded) {
        //         this.printDocument();
        //     }
        // }
    }
    export default toNative(PersonaSummaryPdf);
</script>

<!--<style scoped lang="scss" src="./persona-summary.scss"/>-->
<style lang="scss" src="../../../common/report/report-pdf.scss"/>
