import {AxiosResponse} from 'axios';
import {cloneDeep} from 'lodash-es';
import {defineStore} from 'pinia';
import api, {uuidPattern} from 'Server/api';
import {AudienceDistributionsPricing} from 'Stores/audience/audience-distribution';
import {segmentStrategyTypesEditable} from 'Stores/audience/audience';
import {useAppStore} from 'Stores/common/app';
import {SubmitParameter, SubmitOption, ListParameters, DataClassSetting, DataClassSettings} from 'Stores/common/models';
import {FileMeta} from 'Stores/file';
import {useTagStore} from 'Stores/tag';
import {useUserStore, User, UserTimestamp, UserInvitation} from 'Stores/user';
import {AccountSettings, RequestError} from 'Utilities/immutables';
import {axiosResponse, camelCase, isEmptyArray, isEmptyObject} from 'Utilities/utils';
import {snakeCase} from 'Utilities/utils-static';
import {isUndefinedOrNullOrEmpty} from 'Utilities/inspect';

export interface Account {
    id: string,
    name: string,
    description?: string,
    isInternal?: boolean,
    isActive: boolean,
    owner?: string,
    companyName: string,
    contactName?: string,
    contactPhone?: string,
    contactEmail?: string,
    clientDivId: string,
    vendorDivId: string,
    salesforceId: string,
    multiClient: boolean,
    offlineDatasource: string,
    partnerIdType?: string,
    defaultJobPriority: number,
    defaultJobParameters?: string,
    logoFile?: FileMeta,
    processingState: string,
    maxUserSeats?: number,
    maxPersonaJobs?: number,
    maxAudienceSegments?: number,
    allowDataAppends: boolean,
    allowPartnerIdUpload: boolean,
    dataClassSettings: DataClassSettings,
    transferStrategy: string,
    buildConfig?: JSON | string,
    segmentStrategy?: any[],
    // customSegmentStrategy?: boolean,
    overrideSegmentStrategy?: boolean,
    sftpConfig?: string,
    submitParameters?: Array<SubmitParameter>,
    audienceDistributionsPricing?: AudienceDistributionsPricing,
    businessUse: AccountBusinessUse,
    conglomerateRfmBrands: [],
    conglomerateRfmMarkets: [],
    dictionary: any,
    dmaCodes: [],
    profiles: {},
    sentences: {},
    user?: AccountUser,
    users: AccountUsers,
    maxUsersReached: boolean,
    created: UserTimestamp,
    updated: UserTimestamp,
}

export interface AccountBusinessUse {
    types: [],
}

export interface AccountState {
    account: Account,
    // subHeaders: any,
}

export interface AccountUser extends User {
    name: string,
    userId: string,
    status: string,
    lastStatus: string,
    isInternal: boolean | null,
    isAdmin: boolean,
    ownerEmail: string,
    maxUsersReached: boolean,
    selected?: boolean,
    mode?: string,
}

export interface AccountUsers {
    params: ListParameters,
    data: Array<AccountUser>,
}

export interface AccountUserTokenAuth {
    accountId: string,
    userId: string,
    token: string | (string | null)[],
    status: string,
}

export enum AccountTabs {
    SUMMARY = 'summary',
    USERS = 'users',
    CLIENTS = 'clients',
    DEFAULTS = 'defaults'
}

export enum AccountLoaders {
    VALIDATING = 'validating account',
    LOADING = 'loading account',
    SAVING = 'saving account',
    VIEWING = 'viewing account',
    SENDING = 'sending'
}

export const defaultAccountUser: AccountUser = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    name: '',
    userId: '',
    status: 'inactive',
    lastStatus: '',
    ownerEmail: '',
    maxUsersReached: false,
    timezone: '',
    isInternal: false,
    companyName: '',
    currentAccountId: '',
    inactiveTimeout: 0, // mins
    isActive: false,
    isAdmin: false,
    lastLogin: null,
    lastUsed: null,
    accounts: [],
    created: {},
    updated: {},
};

export const defaultAccountUsersSortParameters: ListParameters = {
    limit: 50,
    offset: 0,
    sort: 'isAdmin',
    order: 'desc',
    search: '',
    append: false
}

export const accountConglomerateRfmBrandsUri: string = `/api/meta/conglomerateRfm/brands`;
export const accountConglomerateRfmMarketsUri: string = `/api/meta/conglomerateRfm/markets`;
export const accountDmaCodesUri: string = `/api/meta/dma?limit=9999`;
export const accountSentencesUri: string = '/api/meta/sentences';
export const getAccountDictionaryDataSources = (currentAccount: Account) => {
    let sources: string[] = ['standard'];

    if (currentAccount.id) {
        if (currentAccount.dataClassSettings.conglomerateRfm?.isPermitted) {
            sources.push('conglomerateRFM');
        }
        if (currentAccount.dataClassSettings.highLevelRfm?.isPermitted) {
            sources.push('highLevelRFM');
        }
    }

    return sources;
}
export const fieldDictionaryUri = '/api/meta/dictionary';
export const metadataLoaderName: string = 'metadata-loading';
export const getAccountProfilesUri = (context: string) => {
    return `/api/audiences/profiles?type=${context}`
}

export const useAccountStore = defineStore('account', {
    state: (): AccountState => ({
        account: {
            id: '',
            name: '',
            description: '',
            isActive: false,
            companyName: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            clientDivId: '',
            vendorDivId: '',
            salesforceId: '',
            multiClient: false,
            offlineDatasource: '',
            partnerIdType: '',
            defaultJobPriority: 9,
            defaultJobParameters: '',
            processingState: '',
            maxUserSeats: 5,
            maxPersonaJobs: 5,
            maxAudienceSegments: 5,
            allowDataAppends: false,
            allowPartnerIdUpload: false,
            dataClassSettings: {} as DataClassSettings,
            transferStrategy: '',
            buildConfig: JSON.parse("{}"),
            segmentStrategy: [],
            // customSegmentStrategy: false,
            sftpConfig: '',
            submitParameters: [],
            audienceDistributionsPricing: {
                id: '',
                accountId: '',
                text: '',
                requested: {},
                confirmationRequired: false,
                approver: {
                    email: '',
                    firstName: '',
                    lastName: ''
                }
            },
            logoFile: cloneDeep( { id: '', name: '', type: '' } ),
            businessUse: {
                types: [],
            },
            conglomerateRfmBrands: [],
            conglomerateRfmMarkets: [],
            dictionary: {
                _accountId: null,
            },
            dmaCodes: [],
            profiles: {},
            sentences: {},
            user: cloneDeep( defaultAccountUser ),
            users: {
                params: cloneDeep( defaultAccountUsersSortParameters ),
                data: []
            },
            maxUsersReached: false,
            created: {},
            updated: {}
        },
        // subHeaders: {
        //     summary: '',
        //     users: 'Add and Manage Users for this Account',
        //     clients: 'Manage Clients and the associated Destination Platforms for each Client',
        //     defaults: 'Set Default Values to be Pre-Populated as Parameters for this Account'
        // }
    }),

    getters: {
        getAccount: (state: AccountState) => state.account.id
            ? state.account
            : JSON.parse( sessionStorage.getItem( 'account' ) || '{}'
        ),
        getAccountAllowSegmentDisplay(state: AccountState): boolean {
            return (segmentStrategyTypesEditable.includes((state.account.segmentStrategy || [])[0]?.strategy));
        },
        getAccountAllowSegmentEdit(state: AccountState): boolean {
            return ((state.account.overrideSegmentStrategy || false) && this.getAccountAllowSegmentDisplay);
        },
        getAccountBusinessUse: (state: AccountState) => state.account.businessUse,
        getAccountConglomerateRfmBrands: (state: AccountState) => state.account.conglomerateRfmBrands || [],
        getAccountConglomerateRfmMarkets: (state: AccountState) => state.account.conglomerateRfmMarkets || [],
        getAccountDefaultSubmitParameter(state: AccountState) {
            const accountSubmitParameters: SubmitParameter[] = cloneDeep( state.account.submitParameters ) || [];
            let defaultParameter: SubmitParameter | null;

            // Note: Currently support 1 account submit parameter; If multiple, default select highest display order
            defaultParameter = accountSubmitParameters ? accountSubmitParameters.find( ( parameter: SubmitParameter ) =>
                ( parameter.displayOrder === 1 ) && parameter.options ) || null : null;

            if ( defaultParameter && defaultParameter.options ) {
                defaultParameter.submitOption = defaultParameter.options.find( ( option: SubmitOption ) => option.displayOrder === 1 ) || undefined;
            }

            return defaultParameter;
        },
        getAccountDictionary: (state: AccountState) => state.account.dictionary || {},
        getAccountDmaCodes: (state: AccountState) => state.account.dmaCodes || [],
        getAccountProfiles: (state: AccountState ) => state.account.profiles || {},
        getAccountSentences: (state: AccountState) => state.account.sentences || {},
        // getAccountSubHeaders: (state: AccountState) => state.subHeaders,
        getAccountUser(state: AccountState) {
            return state.account.user;
        },
        getAccountDataClassSettings(state: AccountState) {
            return state.account.dataClassSettings;
        },
        getAccountUsersSortParameters(state: AccountState) {
            return state.account.users?.params || defaultAccountUsersSortParameters;
        },
        getActiveAccount(state: AccountState) {
            const sessionAccount: string = sessionStorage.getItem( 'account' ) || '';

            return state.account.id ?
                state.account :
                !isUndefinedOrNullOrEmpty(sessionAccount) ?
                    JSON.parse(sessionAccount) :
                    {}
                ;
        },
    },

    actions: {
        allowDataClass(dataClassType: string, businessUseTypeIdentifier: string): boolean {
            switch (dataClassType) {
                case 'predictedSpend':
                    dataClassType = 'consumerSpend'; // TODO: remove after we rename this everywhere
                    break;

                default:
                    // Do nothing
            }
            // console.debug(`[ALLOW DATA CLASS] Checking type "${dataClassType}" against business use ID "${businessUseTypeIdentifier}"`);
            const featureKey = snakeCase(dataClassType).toUpperCase();

            // Check if account allows it
            if (!this.account.dataClassSettings[dataClassType]?.isPermitted) {
                // console.warn(`🔴 Account does not allow ${featureKey} ->`, this.account.dataClassSettings);
                return false;
            }

            // Check if the selected business type also allows it
            const businessType = (this.getAccountBusinessUse?.types || [])
                .find((type: any) => type.identifier === businessUseTypeIdentifier);
            if (businessType) {
                // console.debug(
                //     `Checking for "${featureKey}" in business type "${businessType.shortDescription}" (ALLOW: ${!businessType.restrictedData.includes(featureKey)}) ->`,
                //     businessType.restrictedData.join(',') || 'ALL ALLOWED'
                // );
                return !businessType.restrictedData.includes(featureKey);
            }

            // TODO: if there's no matching business use type, should we fail the check?
            return true;
        },

        clearAccountMetadata() {
            // console.debug('🧽 Clearing account metadata...');
            try {
                this.account.dictionary = {};
                this.account.dmaCodes = [];
                this.account.sentences = {};
                this.account.profiles = {};
                this.account.businessUse = {
                    types: [],
                };
                this.account.conglomerateRfmBrands = [];
                this.account.conglomerateRfmMarkets = [];

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async clearAccountUser() {
            try {
                this.account.user = cloneDeep( defaultAccountUser );

                return true;
              } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async deleteAccountFile( fileId: string ) {
            try {
                const currentAccount = this.getAccount;
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.delete( `/api/accounts/${currentAccount.id}/uploads/${fileId}` );

                if ( response.data ) {
                    return true;
                } else {
                    throw 'File delete failed';
                }
              } catch ( error ) {
                console.error( error );
            }
        },

        async getAccountNameValidation( value: string ) {
            try {
                const currentAccount = this.getAccount;
                const name: string = encodeURIComponent( value );
                const response = await api.getAxiosInstance.get( `/api/accounts/${currentAccount.id}/names/${name}` );

                return response.data?.data;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async getAccountShareToken( { accountId, token } ) {
            try {
                const response = await api.getAxiosInstance.get( `/api/accounts/${accountId}/share/${token}?shared=true` );
                return response.data?.data || null;
            } catch ( error ) {
                console.error( error );
            }
        },

        async refreshActiveAccount(accountId: string) {
            try {
                const currentUser: User = useUserStore().getUser;

                if (currentUser) {
                    const response: any = await api.getAxiosInstance.get(`/api/accounts/${accountId}`);
                    const account: Account = response.data?.data;

                    if (account) {
                        await this.setAccountMetadata(account);

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account`;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveAccountUser( accountUser: AccountUser ) {
            try {
                const currentUser: User = useUserStore().getUser;
                const currentAccountId: string = currentUser.currentAccountId;

                if ( currentUser ) {
                    const body: any = { currentUserId: currentUser.id, user: accountUser };
                    const response: any = await api.getAxiosInstance.put( `/api/accounts/${currentAccountId}/users/${accountUser.id}`, body );
                    const user: any = response.data;

                    if ( user && user.hasOwnProperty( 'data' ) ) {
                        await this.setAccountUser( user.data );
                        return response.data;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account user`;
                }
            } catch (error) {
                console.error(error);
            }
        },

        async setAccountLogo( file: FileMeta ) {
            try {
                let account: Account = cloneDeep( this.getAccount );
                account.logoFile = file;
                this.setSessionAccount( account );
                this.account = account;

                return account;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccountUser( user: AccountUser ) {
            try {
                const currentUser: User = useUserStore().getUser;
                const response = await api.getAxiosInstance.get( `/api/accounts/${currentUser.currentAccountId}/users/${user.id}` );

                if ( response.data?.data?.user ) {
                    this.account.user = response.data.data.user;
                    if ( this.account.user ) {
                        this.account.user.accounts = user.accounts;
                    }

                    return this.account.user;
                } else {
                    return false;
                }
            } catch ( error ) {
                console.error( error );
            }
        },

        async sendAccountUserInvitation( invite: UserInvitation ) {
            const currentAccount = this.getAccount;

            try {
                const body: UserInvitation = invite;
                const response = await api.getAxiosInstance.post( `/api/accounts/${currentAccount.id}/users/invite`, body );

                return response.data?.data;
            } catch ( error ) {
                console.error( error );
            }
        },

        async setAccountUsers( params: ListParameters ) {
            try {
                const currentUser: User = useUserStore().getUser;
                const usersParams: Object = { sort: params.sort, order: params.order, limit: params.limit, offset: params.offset, search: params.search };
                const response: any = await api.getAxiosInstance.get( `/api/accounts/${currentUser.currentAccountId}/users`, { params: usersParams } );

                if ( response.data?.data ) {
                    const accountUsers: AccountUsers = {
                        params: params,
                        data: response.data.data.users
                    }
                    this.account.users = accountUsers;
                    return true;
                } else {
                    throw 'No users';
                }
            } catch ( error ) {
                console.error( error );
            }
        },

        async setAccountUsersSortParameters( params: ListParameters ) {
            try {
                if ( this.account.users ) {
                    this.account.users.params = params;
                } else {
                    this.account.users = {
                        params: params,
                        data: []
                    }
                }

                return true;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccount({accountId, isInitial, shared}) {
            const currentUser: User = useUserStore().getUser;
            const lastValidUserAccount: any = currentUser ?
                await useUserStore().getLastValidUserAccount :
                null;
            const dynamicAccountId = accountId || currentUser.currentAccountId || lastValidUserAccount.id;
            if (!dynamicAccountId) {
                console.error(accountId, isInitial, shared, currentUser, lastValidUserAccount);
                throw '🔥 setAccount - No account ID specified!';
            }

            try {
                if (currentUser) {
                    if (!isInitial && lastValidUserAccount.hasOwnProperty('id') && ((lastValidUserAccount.id === accountId) || !accountId)) {
                        // If no account was specified, or if the specified account matches the cached version, pull account and metadata from storage
                        return await this.setAccountMetadata(lastValidUserAccount);
                    } else if (isInitial) {
                        // Pull account and metadata from API
                        const queryString: string = shared ? `?shared=true` : ``;
                        const response: any = await api.getAxiosInstance.get(`/api/accounts/${dynamicAccountId}${queryString}`);
                        let account: Account = response.data?.data;

                        if (account) {
                            console.debug('📌 Account determined - Retrieving metadata...');
                            await this.setAccountMetadata(account, shared);

                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account`;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountBusinessUseTypes(account: Account, types?: any) {
            try {
                if (types && !isEmptyArray(types)) {
                    if (this.account.hasOwnProperty('businessUse')) {
                        this.account.businessUse.types = types;
                    } else {
                        this.account.businessUse = {
                            types,
                        }
                    }

                    return true;
                } else {
                    console.error('No fallback for setting business use types automatically!')
                    // /*
                    // Query params: search, sort, order, limit, offset
                    // Valid sort values: market(default), category, subcategory, marketId
                    // */
                    // const queryString: string = `selectablePersona=true`;
                    // const response = await api.getAxiosInstance.get(`${accountConglomerateRfmBrandsUri}?${queryString}`);
                    // const brands = response.data?.data?.brands;
                    //
                    // if (brands) {
                    //     this.account.conglomerateRfmBrands = brands;
                    //     return true;
                    // } else {
                    //     return false;
                    // }
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountConglomerateRfmBrands(account: Account, brands?: any) {
            if (!account.dataClassSettings.conglomerateRfmBrand?.isPermitted) {
                this.account.conglomerateRfmBrands = [];
                return false;
            }

            try {
                if (brands && !isEmptyObject(brands)) {
                    this.account.conglomerateRfmBrands = brands;
                    return true;
                } else {
                    /*
                    Query params: search, sort, order, limit, offset
                    Valid sort values: market(default), category, subcategory, marketId
                    */
                    const queryString: string = `selectablePersona=true`;
                    const response = await api.getAxiosInstance.get(`${accountConglomerateRfmBrandsUri}?${queryString}`);
                    const brands = response.data?.data?.brands;

                    if (brands) {
                        this.account.conglomerateRfmBrands = brands;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountConglomerateRfmMarkets(account: Account, markets?: any) {
            if (!account.dataClassSettings.conglomerateRfm?.isPermitted) {
                this.account.conglomerateRfmMarkets = [];
                return false;
            }

            try {
                if (markets && !isEmptyObject(markets)) {
                    this.account.conglomerateRfmMarkets = markets;
                    return true;
                } else {
                    /*
                    Query params: search, sort, order, limit, offset
                    Valid sort values: market(default), category, subcategory, marketId
                    */
                    const queryString: string = `selectablePersona=true`;
                    const response = await api.getAxiosInstance.get(`${accountConglomerateRfmMarketsUri}?${queryString}`);
                    const markets = response.data?.data?.markets;

                    if (markets) {
                        this.account.conglomerateRfmMarkets = markets;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountDictionary(accountId: string, dictionary?: any) {
            try {
                if (dictionary && !isEmptyObject(dictionary)) {
                    // console.debug('📖 Setting field dictionary directly:', dictionary);
                    this.account.dictionary = dictionary;
                    return true;
                } else {
                    const currentAccount = this.getAccount;
                    // console.debug(`📖 Retrieve account dictionary - ${accountId} (Existing: ${currentAccount?.dictionary?._accountId || '🟥 NONE FOUND'})`);
                    let dictionary: any = {
                        _accountId: currentAccount.id,
                    };
                    const sources: string[] = getAccountDictionaryDataSources(currentAccount);
                    const endpoints = sources.map((source: string) => {
                        return api.getAxiosInstance.get(`${fieldDictionaryUri}?source=${source}`);
                    })

                    const responses: AxiosResponse<any>[] = await Promise.all(endpoints);
                    for (const response of responses) {
                        const dictionaryData: any = response.data?.data;
                        dictionary[dictionaryData.dataSources[0]] = dictionaryData.fields;
                    }

                    if (Object.keys(dictionary).length > 1) {
                        this.account.dictionary = dictionary;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountDmaCodes(accountId: string, dmaCodes?: any) {
            try {
                if (dmaCodes && !isEmptyObject(dmaCodes)) {
                    this.account.dmaCodes = dmaCodes;
                    return true;
                } else {
                    const response = await api.getAxiosInstance.get(accountDmaCodesUri);
                    const dmaCodes = response.data?.data;

                    if (dmaCodes) {
                        this.account.dmaCodes = dmaCodes;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccountMetadata(account: Account, shared: boolean = false) {
            if (useAppStore().getLoaderActive(metadataLoaderName)) {
                console.debug('🕓 Metadata is already loading - nothing to do here!');
                return true;
            }

            useAppStore().setLoader(metadataLoaderName);
            // console.debug(`💰 Setting account metadata for ${account.id}`)
            try {
                this.account = account;
                this.setSessionAccount(account);
                useUserStore().setUserCurrentAccountId(account.id);

                // Dictionary must be handled on its own
                let accountDictionarySet: boolean = account.id == this.account?.dictionary?._accountId;
                if (accountDictionarySet) {
                    // console.debug(`🟡 Account dictionary already set for ${account.id} - abort refresh`);
                    useAppStore().clearLoader(metadataLoaderName);
                    return true; // TODO: can we abort the entire metadata pass at this point, or do we need separate logic for each item?
                } else {
                    await this.setAccountDataClassSettings(camelCase(AccountSettings.DATA_CLASSES), shared);
                    await this.setAccountDictionary(account.id);
                }

                await useTagStore().clearAccountTags();
                let accountProfilesUri: string = getAccountProfilesUri('tuning');
                let endpoints: string[] = [
                    `/api/accounts/${account.id}/businessUse/types${shared ? '?shared=true' : ''}`,
                ];

                if (this.account.dataClassSettings.conglomerateRfmBrand?.isPermitted && isEmptyObject(this.account.conglomerateRfmBrands)) {
                    // endpoints.push(`${accountConglomerateRfmBrandsUri}?selectablePersona=true`);
                    endpoints.push(accountConglomerateRfmBrandsUri);
                }

                if (this.account.dataClassSettings.conglomerateRfm?.isPermitted && isEmptyObject(this.account.conglomerateRfmMarkets)) {
                    // endpoints.push(`${accountConglomerateRfmMarketsUri}?selectablePersona=true`);
                    endpoints.push(accountConglomerateRfmMarketsUri);
                }

                if (isEmptyObject(this.account.dmaCodes)) {
                    endpoints.push(accountDmaCodesUri);
                }

                if (isEmptyObject(this.account.profiles)) {
                    endpoints.push(accountProfilesUri);
                }

                if (isEmptyObject(this.account.sentences)) {
                    endpoints.push(accountSentencesUri);
                }

                if (!isEmptyArray(endpoints)) {
                    const responses: AxiosResponse<any>[] = await Promise.all(endpoints.map((endpoint: string) => api.getAxiosInstance.get(endpoint)));

                    for (let i: number = 0; i < responses.length; i++) {
                        if (responses[i]) {
                            const response: any = responses[i];
                            const requestUri: string = response.config?.url;

                            switch (true) {
                                case requestUri.includes(`/businessUse/types`):
                                    await this.setAccountBusinessUseTypes(account, response.data?.data?.businessUseTypes);
                                    break;

                                case requestUri.includes(accountConglomerateRfmBrandsUri):
                                    await this.setAccountConglomerateRfmBrands(account, response.data?.data?.brands);
                                    break;

                                case requestUri.includes(accountConglomerateRfmMarketsUri):
                                    await this.setAccountConglomerateRfmMarkets(account, response.data?.data?.markets);
                                    break;

                                case requestUri.includes(accountDmaCodesUri):
                                    await this.setAccountDmaCodes(account.id, response.data?.data);
                                    break;

                                case requestUri.includes(accountProfilesUri || ''):
                                    await this.setAccountProfiles('tuning', response.data?.data);
                                    break;

                                case requestUri.includes(accountSentencesUri):
                                    await this.setAccountSentences(account.id, response.data?.data);
                                    break;

                                default:
                                    console.error(`Unhandled metadata request URI ${requestUri}`, response);
                            }
                        }
                    }
                }

                useAppStore().clearLoader(metadataLoaderName);

                return true;
            } catch (error) {
                console.error(error);
                useAppStore().clearLoader(metadataLoaderName);
                return false;
            }
        },

        async setAccountDataClassSettings(type: string, shared: boolean = false) {
            try {
                const currentUser: User = useUserStore().getUser;

                if (this.account.id && currentUser) {
                    const queryString: string = shared ? `?shared=true` : ``;
                    const response: any = await api.getAxiosInstance.get( `/api/accounts/${this.account.id}/settings/${type}${queryString}` );
                    const responseData: any = response.data?.data;

                    if ( responseData ) {
                        if (this.account.id = responseData.accountId) {
                            this.account.dataClassSettings = responseData.dataClassSettings;
                            this.setSessionAccount(this.account);
                        }

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account`;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccountPersonaOptions( accountId: string ) {
            try {
                const currentUser: User = useUserStore().getUser;

                if ( currentUser ) {
                    const response: any = await api.getAxiosInstance.get( `/api/accounts/${accountId}/personaOptions` );
                    const responseData: any = response.data?.data;

                    if ( responseData ) {
                        if ( this.account.id = responseData.id ) {
                            this.account.submitParameters = responseData.submitParameters;
                            this.setSessionAccount( this.account );
                        }

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account`;
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccountProfiles(context: string, profiles?: any) {
            try {
                if (profiles && !isEmptyObject(profiles)) {
                    let profileContextData = {};
                    profileContextData[ context ] = profiles;
                    this.account.profiles = profileContextData;
                    return true;
                } else {
                    const response = await api.getAxiosInstance.get(getAccountProfilesUri(context));
                    const profiles = response.data?.data;

                    if (profiles) {
                        let profileContextData = {};
                        profileContextData[ context ] = profiles;
                        this.account.profiles = profileContextData
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setAccountSegmentStrategy( segments: any ) {
            try {
                let currentAccount: any = this.getAccount;

                if ( currentAccount ) {
                    const segmentsJson: string = JSON.stringify( segments );
                    const body: any = { currentUserId: useUserStore().getUser.id, segmentStrategy: segmentsJson };
                    const response = await api.getAxiosInstance.patch( `/api/accounts/${currentAccount.id}`, body );

                    if ( response?.data?.data ) {
                        this.account.segmentStrategy = response.data.data.segmentStrategy;
                        this.setSessionAccount( response.data.data );

                        return response.data;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} account segment strategy`;
                }
              } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },

        async setAccountSentences(accountId: string, sentences?: any) {
            try {
                if (sentences && !isEmptyObject(sentences)) {
                    this.account.sentences = sentences;
                    return true;
                } else {
                    const response = await api.getAxiosInstance.get(accountSentencesUri);
                    const sentences = response.data?.data;

                    if (sentences) {
                        this.account.sentences = sentences;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setAccountSummary( account: Account ) {
            try {
                const body: any = { account: account, currentUserId: useUserStore().getUser.id };
                const response = await api.getAxiosInstance.put(`/api/accounts/${account.id}`, body);

                if ( response?.data?.data ) {
                    Object.assign( this.account, response.data.data );
                    this.setSessionAccount( account );

                    if ( this.account.users.data ) {
                        for( let i = 0; i < this.account.users.data.length; i++ ) {
                            let user: AccountUser = this.account.users.data[ i ];

                            user.isActive = Boolean( user.isActive );
                            user.isAdmin = Boolean( user.isAdmin );
                        }
                    }

                    return response.data;
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        setSessionAccount( account: Account ) {
            try {
                sessionStorage.setItem( 'account', JSON.stringify( account ) );
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }
})
